<template>
  <div>
    <div v-if="showSuccessModal" class="modal" tabindex="-1" role="dialog"
      style="display: flex; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.8);">
      <div style="background-color: var(--white);color: black; max-width: 468px; width: 85%;height: 40vh;"
        class="modal-content">
        <div class="modal-body">
          <div style="border-bottom: solid black 2px;">
            <div class="row m-0 p-0">
              <div class="col-8 p-0 m-0">
                <div class="d-flex">
                  <span style="background-color: var(--darkBackground);"
                    class="btn btn-sm slip-text-header text-light close-text" id="faso-close"
                    @click="showSuccessModal = false">
                    [x]
                  </span>
                </div>
              </div>
              <div class="col-4 p-0 m-0">
                <div style="float: right;" class="d-flex">
                  <span class="slip-text-header">
                  </span>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <img style="width: 150px;margin-top: 60px;" src="/img/check-icons.png" alt="Check">
          </div>
          <br />
          <h5 style="text-align: center;color: var(--darkBackground);">SUCCESS.</h5>
          <br />
          <br />
        </div>
      </div>
    </div>
    <hr class="m-1" />
    <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--white);color: white;">
      <div class="text-light text-center matches-title mb-1">
        <h4 style="border-bottom: 1px solid white;color: var(--darkBackground);">Deposit</h4>
        <span style="color: var(--darkBackground);" class="s-a-1-txt">Top up your Tucheze.com Account</span>
      </div>
      <section class="px-2 mb-3">
        <div class="form-wrapper1 s-a-1 mb-0">
          <div class="mb-0">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;"
              type="number" name="amount" id="deposit" placeholder="10" min="0" class="form-control" value="99"
              aria-label="Amount (to the nearest dollar)" v-model="amount" />
          </div>
          <small style="color: var(--darkBackground);" class="s-a-1-txt mt-3 mb-3">Minimum Deposit amount: Ksh
            10</small>
          <div class="d-flex justify-content-between mb-2">
            <div class="depo-btn-wrapper">
              <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                @click="setAmount(20)">
                +<span class="deposit_val">20</span>
              </button>
            </div>
            <div class="depo-btn-wrapper">
              <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                @click="setAmount(50)">
                +<span class="deposit_val">50</span>
              </button>
            </div>
            <div class="depo-btn-wrapper">
              <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                @click="setAmount(100)">
                +<span class="deposit_val">100</span>
              </button>
            </div>
            <div class="depo-btn-wrapper">
              <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                @click="setAmount(500)">
                +<span class="deposit_val">500</span>
              </button>
            </div>
          </div>

          <button style="background-color: var(--darkBackground);color: var(--white);" @click="deposit"
            id="deposit-profile" v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control">
            Deposit
          </button>
        </div>
      </section>
    </div>

    <hr class="m-1" />
    <br />
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";
import bonusserve from "@/services/bonus";

export default {
  name: "Deposit",
  components: {},
  data: function () {
    return {
      amount: "",
      mb8Balance: "0",
      loading: "",
      pro: {},
      bonus_code: "",
      errorDisplayed: false,
      withdraw_amount: "",
      new_referral_code: "",
      errors: [],
      code: "",
      link: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      showSuccessModal: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // react to route changes...
        document.title = "My Tucheze.com Account";
        document.description = "Manage Tucheze.com account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);

      this.validateReferralLink();
    },
  },
  created() {
    var profile = this.getProfile();

    // Check if the user is logged in
    var isLoggedIn = profile && profile.a;

    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "setting");
    var vm = this;

    vm.myProfile = vm.getProfile();
    // console.log("My Profile data", vm.myProfile);
    this.reloadProfile();

    // this.getBonus();

    if (!vm.myProfile) {
      this.$toast.open({
        message: 'Please login to proceed',
        type: 'error',
        position: 'top'
      });
      this.$router.push({ name: "login", params: {} });
      return;
    }

    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 1000);

    this.code = vm.myProfile.referral_code;
    // this.link = vm.myProfile.referral_link;
    this.initShareInviteModal();
  },
  methods: {
    setAmount: function (amountToAdd) {
      // Convert the current amount to a number
      let currentAmount = parseFloat(this.amount);

      // Check if the current amount is a valid number
      if (isNaN(currentAmount)) {
        currentAmount = 0; // Set it to 0 if it's not a valid number
      }

      // Add the new amount to the existing amount
      currentAmount += amountToAdd;

      // Update the amount with the new total
      this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    },

    generateOrRegenerateReferralCode: function () {
      this.reset();
      this.errors = [];

      var p = this.getProfile();
      var ref = p.referral_code;
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      return ref;
    },

    redeemBonus: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";

      var payload = {
        bonus_code: String(this.bonus_code),
      };

      bonusserve
        .post(path, JSON.stringify(payload), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          console.log("Response for Bonus", res);
          // var bonusdata = res.data.data;

          vm.bonusdata = res.data.data; 7
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          if (err.response) {
            // console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err));
          }
        });
    },


    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.$toast.open({
          message: 'Enter amount atleast 10 KSH or above',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      // vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              vm.logout();
              return;
            } else {
              this.$toast.open({
                message: err.response.data.error_message,
                type: 'error',
                position: 'top'
              });
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    // createReferralLink: function () {
    //   this.reset();
    //   this.errors = [];

    //   var p = this.getProfile();
    //   if (!p) {
    //     this.$toast.open({
    //   message: 'Please login to proceed',
    //   type: 'error',
    //   position: 'top'
    // });
    //     this.$router.push({ name: "login", params: {} });
    //     return;
    //   }

    //   if (this.new_referral_code.length < 4) {
    //     this.errors.push("Referral code should be more than 3 characters");
    //   }

    //   if (this.new_referral_code.length > 20) {
    //     this.errors.push("Referral code should be less than 21 characters");
    //   }

    //   if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
    //     this.errors.push(
    //       "Referral code can only contain letters A-Z and numbers 0 - 9"
    //     );
    //   }

    //   if (this.errors.length > 0) {
    //     return;
    //   }

    //   var vm = this;
    //   var path = process.env.VUE_APP_URL_GENERATE_REFERRAL_LINK.replace(
    //     "{profile_id}",
    //     p.d
    //   );

    //   var data = {
    //     code: this.new_referral_code,
    //   };

    //   vm.loading = "loading";

    //   axios
    //     .post(path, JSON.stringify(data), {
    //       headers: {
    //         "api-key": vm.getAuth(),
    //       },
    //     })
    //     .then((res) => {
    //       vm.loading = "";
    //       vm.link = res.data.message.profile.referral_link;
    //       vm.code = res.data.message.profile.referral_code;
    //       var prof = res.data.message.profile;
    //       var msg = res.data.message.description;

    //       if (prof) {
    //         vm.setProfile(prof);
    //       }

    //       vm.setSuccess("Link Generated", msg);
    //     })
    //     .catch((err) => {
    //       vm.loading = "";

    //       if (err.response) {
    //         if (
    //           parseInt(err.response.status) === 401 ||
    //           parseInt(err.response.status) === 400 ||
    //           parseInt(err.response.status) === 428
    //         ) {
    //           vm.setError(
    //             "Session Expired",
    //             "Your session on this device has expired"
    //           );
    //           vm.logout();
    //           return;
    //         } else {
    //           this.$toast.open({
    //   message: err.response.data.message,
    //   type: 'error',
    //   position: 'top'
    // });
    //           vm.errors.push(err.response.data.message);
    //         }
    //       } else if (err.request) {
    //         vm.setError(
    //           "Network Error",
    //           "Check your network connection and try again"
    //         );
    //         console.log(JSON.stringify(err.request));
    //       } else {
    //         vm.setError(
    //           "Network Error",
    //           "Check your network connection and try again"
    //         );
    //         console.log(JSON.stringify(err));
    //       }
    //     });
    // },
    // validateReferralLink: function () {
    //   this.errors = [];

    //   if (this.new_referral_code.length < 4) {
    //     this.errors.push("Referral code should be more than 3 characters");
    //   }

    //   if (this.new_referral_code.length > 20) {
    //     this.errors.push("Referral code should be less than 21 characters");
    //   }

    //   if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
    //     this.errors.push(
    //       "Referral code can only contain letters A-Z and numbers 0 - 9"
    //     );
    //   }
    // },


    withdraw: function () {
      if (this.loading === 'loading') {
        return;
      }

      this.loading = 'loading';

      var vm = this;

      setTimeout(function () {
        vm.loading = '';
      }, 5000);

      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.withdraw_amount < 100) {
        this.$toast.open({
          message: 'Enter amount at least 100 KSH or above',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var path = process.env.VUE_APP_BASE_WALLET_URL + "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
        // msisdn: parseInt(this.msisdn),
      };

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          console.log("Response for Withdraw", res)
          vm.loading = "";
          var msg = res.data.data;
          if (parseInt(res.data.status) === 200) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          if (parseInt(res.data.status) === 201) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
        })
        .catch((err) => {
          console.log("Error", err)
          vm.loading = "";
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              this.$toast.open({
                message: msg,
                type: 'error',
                position: 'top'
              });
            } else if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              vm.logout();
            } else {
              this.$toast.open({
                message: msg,
                type: 'error',
                position: 'top'
              });
              // console.log(JSON.stringify(err.response.data.message));
            }
            vm.errorDisplayed = true;
          }
        });
    },

    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var profile = this.getProfile();
      if (!profile) {
        return;
      }
      var link = "https://tucheze.com/join?p=" + profile.referral_code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },

    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },

    initShareInviteModal: function () {
      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#shareinvite-modal")) {
          modal.style.display = "none";
        }
      });
    },
  },
  computed: {
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },
    bonus: function () {
      return this.formatCurrency(
        this.profile.balance + this.profile.pending_activation
      );
    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu Tucheze.com, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
    // has_referral_code: function () {
    //   return this.code !== undefined && this.code.length > 3;
    // },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2023-11-22T12:00:00");
      const endDate = new Date("2023-11-22T15:30:00");

      return now >= startDate && now <= endDate;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "KES",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>
